







































































































import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { inject } from 'inversify-props';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import OrderModel from '@/models/crm/order.model';
import OrderFiscalDocumentModel from '@/models/crm/order-fiscal-document.model';
import ContentDialog from '@/components/content-dialog.vue';
import CrmOrderItemsList from '@/components/crm/order-items-list.vue';
import SettingsService from '@/services/crm/settings.service';
import OrderService from '@/services/crm/order.service';

type FeaturedCard = {
  title: string;
  value: string;
  class?: string;
  cols: {
    lg: number;
    md: number;
  };
};

@Component({
  components: {
    ContentDialog,
    CrmOrderItemsList,
  },
})
export default class CrmOrderDetails extends Vue {
  @inject(InjectionIdEnum.CrmSettingsService)
  private settingsService!: SettingsService;

  activeNfe: OrderFiscalDocumentModel | null = null;

  showDetailItemsDialog = false;

  showNfeViewerDialog = false;

  @Prop({ required: true })
  readonly order!: OrderModel;

  @Emit('show-items')
  onShowItemsClicked(): OrderModel {
    this.showDetailItemsDialog = true;

    return this.order;
  }

  @Emit('show-nfe')
  onShowNfeClicked(fiscalDocument: OrderFiscalDocumentModel): OrderFiscalDocumentModel {
    this.activeNfe = fiscalDocument;
    window.open(OrderService.getNfeUrl(this.activeNfe.chaveNFE), '_blank');
    return fiscalDocument;
  }

  onNfeViewerChange(): void {
    if (!this.showNfeViewerDialog) {
      setTimeout(() => {
        this.activeNfe = null;
      }, 450);
    }
  }

  get featuredCards(): FeaturedCard[] {
    let alternatedCard = {
      title: `${this.$t('crm.orderDetails.grossValue')}`,
      value: this.$n(this.order.valorBruto, 'currency'),
      cols: { lg: 2, md: 6 },
    };

    if (this.order.valorSaldo > 0) {
      alternatedCard = {
        title: `${this.$t('crm.orderDetails.balanceValue')}`,
        value: this.$n(this.order.valorSaldo, 'currency'),
        cols: { lg: 2, md: 6 },
      };
    }

    return [
      {
        title: `${this.$t('crm.orderDetails.netValue')}`,
        value: this.$n(this.order.valorLiquido, 'currency'),
        class: 'text--primary',
        cols: { lg: 2, md: 6 },
      },
      alternatedCard,
      {
        title: `${this.$t('crm.orderDetails.situation')}`,
        value: this.order.situacao,
        class: 'text--primary',
        cols: { lg: 4, md: 12 },
      },
      {
        title: `${this.$t('crm.orderDetails.discountValue')}`,
        value: this.$n(this.order.valorDesconto || 0, 'currency'),
        cols: { lg: 2, md: 6 },
      },
      {
        title: `${this.$t('crm.orderDetails.discount')}`,
        value: `${this.$n(this.order.percDesconto1 || 0, 'decimal')}%`,
        cols: { lg: 2, md: 6 },
      },
    ];
  }

  get detailItemsDialogTitle(): string {
    return `${this.$t('crm.orderDetails.dialog.detailItems.title', {
      orderNumber: this.order && this.order.codPedido,
    })}`;
  }

  get nfeViewerDialogTitle(): string {
    return `${this.$t('crm.orderDetails.dialog.nfeViewer.title', {
      orderNumber: this.order && this.order.codPedido,
    })}`;
  }
}
